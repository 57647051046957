import React from "react";
import CarouselComponent from "../Carousel/CarouselComponent";
import NavBarComponent from "../NavBar/NavBarComponent";
import SectionA from "../SectionA/SectionA";
import SectionB from "../SectionB/SectionB";
import SectionC from "../SectionC/SectionC";
import SectionE from "../SectionE/SectionE";
import FooterNew from "../FooterNew/FooterNew";
import Quiz from "../Quiz/Quiz";
import SectionF from "../SectionF.js/SectionF";

const Home = props => {
  return (
    <>
      <SectionA />
      <SectionB />
      <SectionC />
      <SectionE />
      <Quiz />
      <SectionF />
    </>
  );
};

export default Home;
