import React, { Component } from "react";
import styled from "styled-components";
import media from "../../media";
import nextArrow from "../../img/nextArrow.svg";
import backArrow from "../../img/backArrow.svg";
import image5 from "../../img/image5.jpg";
import image4 from "../../img/image4.jpg";
import image3 from "../../img/image3.jpg";
import image2 from "../../img/image2.jpg";
import image1 from "../../img/image1.jpg";
import {
  Carousel,
  CarouselItem,
  CarouselControl,
  CarouselIndicators
} from "reactstrap";

// ITEMS FOR CAROSUEL
const items = [
  {
    src: image1,
    text: "REAGUJĘ",
    text2: "BO MAM SIŁĘ"
  },
  {
    src: image2,
    text: "REAGUJĘ",
    text2: "BO TO W MOIM STYLU"
  },
  {
    src: image3,
    text: "REAGUJĘ",
    text2: "BO KTOŚ MUSI BYĆ PIERWSZY"
  },
  {
    src: image4,
    text: "REAGUJĘ",
    text2: "BO TO TEŻ MOJA SPRAWA"
  },
  {
    src: image5,
    text: "REAGUJĘ",
    text2: "BO LICZY SIĘ CZAS"
  }
];

// STYLED

const Wrapper = styled.div`
  position: relative;
  img {
    height: auto;
    min-height: 250px;
    width: 100vw;

    object-fit: cover;
  }

    .carousel-indicators .active {
      background: ${({ theme }) => theme.colors.orange};
    }

    .carousel-control-next-icon {
      background-image: url('${nextArrow}') !important;
      margin-top: 10px;
      height: 16px;
      :hover {
        cursor: pointer;
      }

      ${media.tablet`
      height: 25px;
      margin-right: 10vw;
      `}
      ${media.large`
      margin-top: 60px;
      margin-right: 10vw;
      `}
    }

    .carousel-control-prev-icon {
      height: 16px;
      margin-top: 10px;
      background-image: url('${backArrow}') !important;
      :hover {
        cursor: pointer;
      }

      ${media.tablet`
      height: 25px;
      margin-left: 10vw;
      `}

      ${media.large`
      margin-top: 60px;
      margin-left: 10vw;
      `}
    }
    
`;

const CarouselStyled = styled(Carousel)`
  .carousel-inner {
    position: relative;
  }
`;

const Text = styled.div`
  position: absolute;
  top: 60%;
  left: 50%;
  transform: translate(-50%, -60%);
  text-align: center;
  p {
    padding-top: 20px;
    font-family: "Bebas Neue Bold";
    margin: 0;
    padding: 0;
    color: white;
    line-height: 0.8;
  }

  p:nth-child(1) {
    font-size: 4.5em;
    color: ${({ theme }) => theme.colors.orange};
  }
  p:nth-child(2) {
    font-size: 1.2em;
  }

  ${media.tablet`
  top: 60%;
  left: 50%;
  transform: translate(-50%, -60%);
  p:nth-child(1) {
    font-size: 7em;

  }
  p:nth-child(2) {
    font-size: 1.8em;
  }
  `}

  ${media.large`
  padding-top: 40px;
  p:nth-child(1) {
    font-size: 10em;

  }
  p:nth-child(2) {
    font-size: 2.6em;
  }
  `}
`;

const CarouselIndicatorsStyled = styled(CarouselIndicators)`
  li {
    border-radius: 50%;
    width: 10px;
    height: 10px;
    border: none;
    margin: 0px 5px;
    opacity: 1;
  }

  ${media.large`
  li {
    width: 18px;
height: 18px;
margin: 20px 8px;
  }

  `}
`;

const MouseIcon = styled.div`
  position: absolute;
  bottom: 65px;
  left: 50%;
  transform: translate(-50%);

  .mouse {
    box-shadow: 0px 2px 14px -1px rgba(0,0,0,0.75);
    display: block;
    width: 18px;
    height: 30px;
    border-radius: 11px 11px 15px 15px;
    border: 1px solid #fff;
    position: absolute;
    left: 50%;
    margin-left: -13px;
    z-index: 9999;
  }

  .mouse span {
    display: block;
    margin: 6px auto;
    width: 2px;
    height: 5px;
    border-radius: 4px;
    background: #fff;
    border: 1px solid transparent;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-name: scroll;
    animation-name: scroll;
  }

  @keyframes scroll {
    0% {
      opacity: 1;
      -webkit-transform: translateY(0);
      -ms-transform: translateY(0);
      transform: translateY(0);
    }
    100% {
      opacity: 0;
      -webkit-transform: translateY(20px);
      -ms-transform: translateY(20px);
      transform: translateY(20px);
    }
  }

  ${media.tablet`
  bottom: 80px; 
  .mouse {
    width: 28px;
  height: 48px;
  }
  `}

  ${media.large`
  bottom: 100px;
  .mouse {
    width: 22px;
  height: 40px;
  } 
  `}

  ${media.huge`
  bottom: 140px; 
  .mouse {
    width: 38px;
  height: 65px;
  }
  `}
`;

export class CarouselComponent extends Component {
  state = {
    activeIndex: 0,
    autoPlay: true
  };

  // CAROUSEL FUNCTIONS TO NAVIGATE

  onExiting = () => {
    this.animating = true;
  };

  onExited = () => {
    this.animating = false;
  };

  next = () => {
    if (this.animating) return;
    const nextIndex =
      this.state.activeIndex === items.length - 1
        ? 0
        : this.state.activeIndex + 1;
    this.setState({ activeIndex: nextIndex });
  };

  previous = () => {
    if (this.animating) return;
    const nextIndex =
      this.state.activeIndex === 0
        ? items.length - 1
        : this.state.activeIndex - 1;
    this.setState({ activeIndex: nextIndex });
  };

  goToIndex = newIndex => {
    if (this.animating) return;
    this.setState({ activeIndex: newIndex });
  };

  componentWillMount = () => {
    this.handleRoutesImage();
  };

  handleRoutesImage = () => {
    switch (this.props.match.params.param) {
      case "":
        return;
      case "param1":
        return this.setState({ activeIndex: 0, autoPlay: false });
      case "param2":
        return this.setState({ activeIndex: 1 });
      case "param3":
        return this.setState({ activeIndex: 2 });
      case "param4":
        return this.setState({ activeIndex: 3 });
      case "param5":
        return this.setState({ activeIndex: 4 });
      default:
        this.props.history.push("/");
    }
  };

  render() {
    const { activeIndex, autoPlay } = this.state;

    const slides = items.map(item => {
      return (
        <CarouselItem
          onExiting={this.onExiting}
          onExited={this.onExited}
          key={item.src}
        >
          <img src={item.src} alt={item.altText} />
          <Text>
            <p> {item.text}</p>
            <p> {item.text2}</p>
          </Text>
        </CarouselItem>
      );
    });

    return (
      <Wrapper>
        <CarouselStyled
          activeIndex={activeIndex}
          next={this.next}
          previous={this.previous}
          ride="carousel"
          interval={autoPlay ? "3000" : false}
        >
          <CarouselIndicatorsStyled
            items={items}
            activeIndex={activeIndex}
            onClickHandler={this.goToIndex}
          />
          {slides}
          <MouseIcon />
          <CarouselControl
            direction="prev"
            directionText="Previous"
            onClickHandler={this.previous}
          />
          <CarouselControl
            direction="next"
            directionText="Next"
            onClickHandler={this.next}
          />
        </CarouselStyled>
        <div className="mouse">
          <span />
        </div>
      </Wrapper>
    );
  }
}

export default CarouselComponent;
