import React from "react";
import { Container } from "reactstrap";
import styled from "styled-components";
import image1 from "../../img/sectionF/image1.png";
import image2 from "../../img/sectionF/image2.png";
import image3 from "../../img/sectionF/image3.png";
import image4 from "../../img/sectionF/image4.png";
import media from "../../media";
const Wrapper = styled.div`
  text-align: center;
  padding: 7em 0 0 0;
  h2 {
    font-size: 40px;
    font-family: "Bebas Neue Bold";
    margin: 0;
  }
  p {
    margin: 0;
    padding: 0;
    letter-spacing: 0.8;
    font-family: "Roboto-Bold";
  }

  ${media.tablet`
  h2 {
    font-size: 55px;
  }
  `}
`;
const Grid = styled.div`
  padding: 10px 0;
  img {
    height: 60px;
  }
  h2 {
    padding: 20px 0 0 0;
    margin: 0;
    font-size: 26px;
  }
  p {
    font-family: "Roboto-Regular";
    font-size: 16px;
    font-weight: 500;
    line-height: 25px;
  }

  ${media.tablet`
    padding-top: 2em;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
    `}

  ${media.large`
    flex-wrap: nowrap;
    p {
        font-size: 18px;
    }
    `}
`;

const Item = styled.div`
position: relative;
margin: 20px 0;




    p {
    margin:0 auto;
    font-family: 'Roboto-Regular';
    max-width: 250px;
    }

    img {
        margin-bottom: 21px;
    }
    ${media.tablet`
    padding: 20px 40px;
    `}

    ${media.large`
    padding: 0px 10px;
 `}

 ${media.huge`
 padding: 20px 20px;
 max-width: 250px;

 :nth-child(4) {
  max-width: 300px;
}

 &::after {
    content:"";
    position: absolute;
    bottom: -10px;
    left: 0;
    right: 0;
    height: 100%;
    border-bottom: 1px solid ${({ theme }) => theme.colors.darkGreen};
    z-index: -1;
}


 :nth-child(1n) {
  &::before {
    content:"";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 90%;
    z-index: -1;
    border-right: 1px solid ${({ theme }) => theme.colors.darkGreen};
}
}

:nth-child(4) {
  p {
  max-width: 300px;
 }
  &::after {
    content:"";
    position: absolute;
    bottom: -10px;
    left: 0;
    right: 0;
    height: 100%;
    z-index: -1;
}
&::before {
  border-right: none;
}
}

 `}

`;

const RobotoBold = styled.span`
  font-family: "Roboto-Bold";
`;

const DownText = styled.div`
  margin-top: 40px;
  padding-bottom: 40px;

  text-align: center;

  p {
    margin-bottom: 20px;
    font-family: "Roboto-Regular" !important;
  }

  ${media.large`
  font-size: 18px;
  `}
`;

const SectionF = () => {
  return (
    <Wrapper name="sectionF">
      <Container>
        <h2>szkolenia dla firm</h2>
        <p>ZAMÓW DLA SWOICH PRACOWNIKÓW SZKOLENIA, KTÓRE:</p>
        <Grid>
          <Item>
            <img src={image1} alt="" />
            <p>
              Zapewnią <RobotoBold> rozwój kompetencji miękkich </RobotoBold>{" "}
              Twoich pracowników.
            </p>
          </Item>
          <Item>
            <img src={image2} alt="" />
            <p>
              Wzmocnią <RobotoBold> zaangażowanie społeczne </RobotoBold>{" "}
              organizacji.
            </p>
          </Item>
          <Item>
            <img src={image3} alt="" />
            <p>
              Sprawią, że w miejscu pracy wszyscy będą mogli{" "}
              <RobotoBold> czuć się bezpiecznie. </RobotoBold>
            </p>
          </Item>
          <Item>
            <img src={image4} alt="" />
            <p>
              Pokażą, że jako pracodawca
              <RobotoBold> nie jesteś </RobotoBold>
              <RobotoBold> obojętny na agresję i nienawiść.</RobotoBold>
            </p>
          </Item>
        </Grid>
        <DownText>
          <p>
            Szkolenia przygotowane zostały z myślą o osobach pracujących z
            klientem. <br />
            Celem jest wskazanie, jak odpowiednio reagować na rozmaite sytuacje
            przemocowe i dyskryminację w miejscu pracy.
          </p>

          <p>
            Działania pozwalają stworzyć wizerunek miejsca przyjaznego i
            bezpiecznego, a także zapobiec kryzysom reputacyjnym, które często
            powstają przez nieadekwatną reakcję. <br />
            Istnieje także możliwość przeprowadzenia szkoleń dla kadry
            menadżerskiej czy HR, a także uczestnictwa w warsztatach otwartych.
          </p>
          <p>
            Dowiedz się wiecej o pakietach szkoleniowych: <br />
            Piotr Godzisz <br />
            pgodzisz@lambdawarszawa.org
          </p>
        </DownText>
      </Container>
    </Wrapper>
  );
};

export default SectionF;
