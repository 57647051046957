export const theme = {
  colors: {
    lightGrey: "#212529",
    darkBlack: "#010101",
    white: "#ffffff",
    black: "#000000",
    darkGreen: "#1a8f3b",
    lightGreen: "#9dd525",
    orange: "#ffa300",
    grey: "#efefef",
    // grey2: "#f7f7f7", OLD BACKGROUND COLOR LIGHT GREY
    grey2: "#eaeaea",
    grey3: "#f0f0f0",
    brown: "#4c3c4a",
    red: "#ed2028"
  }
};
