import React from "react";
import styled from "styled-components";
import media from "../../media";
import man from "../../img/man.png";
import dot from "../../img/dot.svg";
import { Container } from "reactstrap";
const Wrapper = styled.div`
  padding-top: 3em;
`;

const Text = styled.div`
  text-align: center;
  color: #010101;
  font-family: "Bebas Neue Bold";
  font-weight: 400;

  p {
    font-family: "Roboto-Regular";
    font-size: 13px;
    font-weight: 400;
    letter-spacing: -0.51px;
  }

  ${media.large`
  h1 {
    font-size: 64px;
  }
  p {
    font-size: 18px;
font-weight: 400;
line-height: 25px;
      padding: 0 5vw;
    }
  `}
`;
const Picture = styled.div`
  padding-top: 3em;
  font-family: "Roboto-Regular";
  font-weight: 400;
  font-size: 16px;
  text-align: center;
  line-height: 20px;
  div {
    padding: 0.5em 10vw;

    img {
      padding-bottom: 0.5em;
    }
  }

  ${media.tablet`
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  
  div {
      padding: 0;
      img {
      padding-bottom: 0;
    }
  }
  `}
`;
const Man = styled.img`
  display: none;

  ${media.tablet`
  display: block;
   height: 300px;
  position: absolute;
  left: 50%;

  transform: translateX(-50%);
  `}

  ${media.large`
  height: 383px;
  `}
`;

const Item1 = styled.div`
  ${media.tablet`
  grid-column-start: 1;
  grid-column-end: 4;
  grid-row-start: 1;
  text-align: right;

  img {
    position: relative;
    top: 20px;
    right: -25px;
  }
  `}
`;
const Item2 = styled.div`
  ${media.tablet`
  grid-column-start: 1;
  grid-column-end: 4;
  grid-row-start: 2;
  text-align: right;

  img {
    position: relative;
    top: 20px;
    right: -25px;
  }
  `}

  ${media.large`
    margin-top: 4em;
`}
`;
const Item3 = styled.div`
  ${media.tablet`
  
  grid-column-start: 10;
  grid-column-end: 13;
  grid-row-start: 1;
  text-align: left;

  img {
    position: relative;
    top: 20px;
    left: -25px;
  }
`}
  ${media.huge`
grid-column-start: 9;
  grid-column-end: 12;
`}
`;
const Item4 = styled.div`
  p {
    color: ${({ theme }) => theme.colors.black};
    font-weight: 700;
  }

  ${media.tablet`
  
  grid-column-start: 10;
  grid-column-end: 13;
  grid-row-start: 2;
  text-align: left;

  img {
    position: relative;
    top: 20px;
    left: -25px;
  }
`}

  ${media.large`
    margin-top: 4em;
`}

${media.huge`
grid-column-start: 9;
  grid-column-end: 12;
`}
`;

const RobotoBold = styled.span`
  font-family: "Roboto-Bold";
  font-weight: 700;
`;

const GreenFont = styled.span`
  color: ${({ theme }) => theme.colors.darkGreen};
`;
const SectionC = () => {
  return (
    <Wrapper name="sectionC">
      <Container>
        <Text>
          <h1>
            ZANIM ZAREAGUJESZ, <GreenFont> PAMIĘTAJ </GreenFont>
          </h1>
          <p>
            <RobotoBold>Przemoc ma wiele twarzy i wiele odcieni,</RobotoBold>{" "}
            dlatego istnieje również wiele sposobów, by na nią odpowiednio
            zareagować. Reakcja jednak nie zawsze oznacza konfrontację z osobą
            atakującą. Nie jesteś osobą odpowiedzialną za sytuację przemocową,
            nawet jeśli nie zareagujesz…
            <RobotoBold> ALE </RobotoBold> Twoja reakcja może ją zatrzymać.
            Czasem wystarczy jedynie{" "}
            <RobotoBold> drobna interwencja.</RobotoBold> Zawsze, kiedy
            poczujesz zagrożenie, możesz wezwać Policję lub inne służby.
            Konfrontacja ze sprawcą może przekierować atak na ciebie, więc
            działanie warte jest podejmowania wtedy, gdy wiesz, że ewentualny
            atak ci nie zagrozi, a osobie atakowanej pomoże.
          </p>
        </Text>
        <Picture>
          <Man src={man} alt="" />
          <Item1>
            <img src={dot} alt="" />
            <p>
              Dobierz sposób reakcji do swoich{" "}
              <RobotoBold> realnych </RobotoBold>
              możliwości i oceny bezpieczeństwa – nie bądź bohaterem na siłę.
            </p>
          </Item1>
          <Item2>
            <img src={dot} alt="" />
            <p>
              Oszacuj szanse na to, czy inne osoby się do ciebie przyłączą.
              Jeżeli to możliwe, zwróć się do nich o pomoc – reagujcie razem, w
              grupie siła!
            </p>
          </Item2>
          <Item3>
            <img src={dot} alt="" />
            <p>
              Podejmowane kroki dostosuj <RobotoBold> do celu, </RobotoBold>{" "}
              jaki chcesz osiągnąć reakcją.
            </p>
          </Item3>
          <Item4>
            <img src={dot} alt="" />
            <p>
              Uwagę skupiaj na osobie, która jest atakowana, a nie na sprawcy.
            </p>
          </Item4>
        </Picture>
      </Container>
    </Wrapper>
  );
};

export default SectionC;
