import React, { useState, useEffect } from "react";
import styled from "styled-components";
import media from "../../media";
import { Link, animateScroll as scroll } from "react-scroll";
import quizIcon from "../../img/quizIcon.png";

const Wrapper = styled.div`
  position: fixed;
  top: 50%;
  left: 0px;
  display: ${({ inView }) => (inView ? "none" : "block")};
  :hover {
    cursor: pointer;
  }
  z-index: 999;
`;
const Shape = styled.div``;

const Icon = styled.img`
  height: 60px;

  ${media.tablet`
  height: 80px;
  `}

  ${media.large`
  height: 100px;
  `}
`;

const checkViewSmall = changeInView => {
  if (window.pageYOffset > 2670 && window.pageYOffset < 3350) {
    changeInView(true);
  } else {
    changeInView(false);
  }
};

const checkViewMedium = changeInView => {
  if (window.pageYOffset > 1800 && window.pageYOffset < 2400) {
    changeInView(true);
  } else {
    changeInView(false);
  }
};

const checkViewLarge = changeInView => {
  if (window.pageYOffset > 2005 && window.pageYOffset < 2770) {
    changeInView(true);
  } else {
    changeInView(false);
  }
};

const FixedComponent = () => {
  const [inView, changeInView] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", function() {
      if (window.innerWidth < 728) return checkViewSmall(changeInView);
      if (window.innerWidth > 728 && window.innerWidth < 1024)
        return checkViewMedium(changeInView);
      if (window.innerWidth > 1024) return checkViewLarge(changeInView);
    });
  }, []);

  return (
    <Wrapper inView={inView}>
      <Shape>
        <Link
          to="Quiz"
          smooth
          offset={-200}
          // onClick={() => this.toggle()}
        >
          <Icon src={quizIcon} alt="" />
        </Link>
      </Shape>
    </Wrapper>
  );
};

export default FixedComponent;
