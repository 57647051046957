import React from "react";
import styled from "styled-components";
import media from "../../media";
import { Container } from "reactstrap";
const Wrapper = styled.div`
  text-align: center;
  padding-top: 3em;
  h2 {
    margin-bottom: 0.5em;
    color: ${({ theme }) => theme.colors.darkBlack};
    font-family: "Bebas Neue Bold";
    font-size: 40px;
    font-weight: 400;
  }
  p {
    font-family: "Roboto-Regular";
    font-size: 13px;
    margin-bottom: 3em;
    font-weight: 400;
  }

  ${media.tablet`
  
  h2 {
    font-size: 45px;
  }

  p {
    font-size: 15px;
  }
  `}

  ${media.large`
  padding-top: 8em;
  h2 {
    font-size: 55px;
  }

  p {
    font-size: 18px;
  }
  `}

  ${media.huge`
  padding-top: 10em;
h2 {
  font-size: 65px;
}
`}

`;

const List = styled.p`
line-height: 25px;
  ${media.tablet`
padding: 0 12vw;
`}

${media.large`
line-height: 30px;
padding: 0 18vw;
`}
${media.huge`
padding: 0 200px;
`}
`;

const Text = styled.p`
  ${media.tablet`
padding: 0 6vw;
line-height: 25px;
`}

  ${media.huge`
padding: 0 4vw;
`}
`;

const GreenSpan = styled.span`
  color: ${({ theme }) => theme.colors.darkGreen};
`;

const Bold = styled.span`
  font-family: "Roboto-Bold";
  font-weight: 700;
`;
const SectionE = () => {
  return (
    <Wrapper name="sectionE">
      <Container>
        <h2>
          Jak <GreenSpan> reagować?</GreenSpan>
        </h2>
        <List>
          • Skieruj uwagę sprawcy na siebie, jednocześnie odwracając ją od osoby
          atakowanej. <br /> • Jak? Na przykład poprzez wskazanie, że nie
          zgadzasz się na tego rodzaju zachowanie w przestrzeni, w której
          przebywasz. <br />• Wskaż, że zachowanie to będzie miało konsekwencje
          dla sprawcy. <br />• Jeśli sprawca nie zaprzestanie ataku, zwróć się
          do osoby, która może zastosować inne środki - ochroniarza, kierowcy
          autobusu, sprzedawcy itp.
          <br />• Powiedz wprost, że wezwiesz Policję (jednocześnie pamiętaj, że
          nie masz obowiązku ostrzegać sprawcy, że to zrobisz).
          <br />• Wezwij Policję - zadzwoń na numer 112 lub 997 i powiedz, co
          się dzieje.
        </List>
        <Text>
          Jeśli konfrontacja ze sprawcą jest dla Ciebie zbyt niekomfortowa lub
          ryzykowna, możesz odejść z miejsca zdarzenia i zawiadomić inne osoby,
          które zareagują. Nie musisz też wracać w to miejsce, jeśli obawiasz
          się, że sprawca powiąże Cię z reakcją i perspektywa ta jest dla Ciebie
          niekomfortowa.
        </Text>
      </Container>
    </Wrapper>
  );
};

export default SectionE;
