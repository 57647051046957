import React from "react";
import styled from "styled-components";
import media from "../../media";
import infografika from "../../download_files/infografika.jpg";
import logo from "../../download_files/logo.zip";
import kampania from "../../download_files/po-prostu-reaguj-kampania.pdf";
const Wrapper = styled.div`
  padding: 1em 0 3em 0;
  h2 {
    padding: 0 0 0 0 !important;
    font-family: "Bebas Neue Bold";
    font-size: 39px;
    text-align: center;
  }

  ${media.large`
  h2 {font-size: 64px;}
  `}
`;

const DownloadItemsWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
`;
const DownloadItem = styled.a`
  position: relative;
  display: inline-block;
  font-family: "Roboto-Bold";
  font-size: 16px;
  color: ${({ theme }) => theme.colors.darkBlack};
  text-transform: uppercase;
  margin: 10px 0;

  :nth-child(1),
  :nth-child(2) {
    ::after {
      content: "";
      position: absolute;
      bottom: -10px;
      left: 50%;
      transform: translateX(-50%);
      border-bottom: #b5b5b4 solid 1px;
      width: 200px;
    }
  }

  :hover {
    cursor: pointer;
    color: ${({ theme }) => theme.colors.darkBlack};
    text-decoration: none;
  }

  ${media.tablet`
  font-size: 18px;
  `}

  ${media.large`
  font-size: 29px;

  :nth-child(1),
  :nth-child(2) {

    ::after {
        border-bottom: #b5b5b4 solid 2px;
        width: 330px;
    }
  }
  `}
`;

const GreenSpan = styled.span`
  color: ${({ theme }) => theme.colors.darkGreen};
`;

const DownloadFiles = () => {
  return (
    <Wrapper>
      <h2>
        Do <GreenSpan>Pobrania </GreenSpan>
      </h2>
      <DownloadItemsWrapper>
        <DownloadItem href={logo} download>
          LOGO
        </DownloadItem>
        <DownloadItem href={kampania} download>
          Informacja prasowa
        </DownloadItem>
        <DownloadItem href={infografika} download>
          infografika
        </DownloadItem>
      </DownloadItemsWrapper>
    </Wrapper>
  );
};

export default DownloadFiles;
