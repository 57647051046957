import React, { Component } from "react";
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import { createGlobalStyle, ThemeProvider } from "styled-components";
import { theme } from "./theme";
import Home from "./components/Home/Home";
import NavBarComponent from "./components/NavBar/NavBarComponent";
import CarouselComponent from "./components/Carousel/CarouselComponent";
import FooterNew from "./components/FooterNew/FooterNew";
import ReactGA from "react-ga";
import FixedComponent from "./components/FixedComponent/FixedComponent";

const GlobalStyle = createGlobalStyle`
body {
margin: 0;
padding: 0;
font-family: 'Bebas Neue Regular', sans-serif ;
overflow-x: hidden !important;
}
`;

ReactGA.initialize("UA-139971325-1");
ReactGA.pageview(window.location.pathname + window.location.search);

class App extends Component {
  render() {
    return (
      <Router>
        <ThemeProvider theme={theme}>
          <>
            <GlobalStyle />
            <Route path="/" component={NavBarComponent} />
            <Route exact path="/" component={CarouselComponent} />
            <Route exact path="/:param" component={CarouselComponent} />
            <Route path="/" component={Home} />
            <Route path="/" component={FooterNew} />
            <Route path="/" component={FixedComponent} />
          </>
        </ThemeProvider>
      </Router>
    );
  }
}

export default App;
