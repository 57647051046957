import React from "react";
import styled from "styled-components";
import media from "../../media";

const Wrapper = styled.div`
  position: relative;
  height: 60px;
  width: 60px;
  margin: 5px;
  background: ${props => props.color};

  p {
    position: absolute;
    left: 50%;
    top: 55%;
    transform: translate(-50%, -50%);
    color: white;
    text-align: center;
    line-height: 18px;
    font-size: 1.2em;
  }

  ${media.phone`
  height: 70px;
  width: 70px;
  `}
`;
const Tile = props => {
  return (
    <Wrapper color={props.color}>
      <p>{props.price} </p>
    </Wrapper>
  );
};

export default Tile;
