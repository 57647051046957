import React from "react";
import styled from "styled-components";
import media from "../../media";
import { Container } from "reactstrap";
import people from "../../img/people.png";
import person from "../../img/person.png";
import sound from "../../img/sound.png";
import Hand from "../../img/Hand.png";
import handsPeople from "../../img/handsPeople.png";

const Wrapper = styled.div`
  padding: 1em 0 1em 0;
  text-align: center;
  font-family: "Bebas Neue Bold";
  color: ${({ theme }) => theme.colors.darkBlack};
  h3 {
    font-size: 2em;
  }

  background: ${({ theme }) => theme.colors.grey2};

  ${media.large`
  h3 {
    font-size: 2.6em;
  }
  `}
`;
const Grid = styled.div`
  padding: 10px 0;
  img {
    height: 60px;
  }
  h2 {
    padding: 20px 0 0 0;
    margin: 0;
    font-size: 26px;
  }
  p {
    font-family: "Roboto-Regular";
    font-size: 16px;
    font-weight: 500;
    line-height: 17px;
  }

  ${media.tablet`
padding-top: 1em;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
    `}

  ${media.large`
    flex-wrap: nowrap;
    `}
`;

const Item = styled.div`
transition: 0.5s;
  ${media.tablet`
 padding: 20px 40px;
 `}
  ${media.large`
 padding: 20px 10px;
 `}
 ${media.huge`
 padding: 20px 20px;
 `}
`;

const SectionB = () => {
  return (
    <Wrapper name="sectionB">
      <Container>
        <h3>TWOJA REAKCJA MOŻE: </h3>
        <Grid>
          <Item>
            <img src={handsPeople} alt="" />
            <h2>Realnie wesprzeć</h2>
            <p>
              osobę doświadczającą <br />
              przemocy.
            </p>
          </Item>
          <Item>
            <img src={people} alt="" />
            <h2>zwrócić uwagę</h2>
            <p>
              innych świadków <br /> przemocy na sytuację.
            </p>
          </Item>
          <Item>
            <img src={sound} alt="" />
            <h2>sprawić,</h2>
            <p>
              że inne osoby również
              <br /> w przyszłości zareagują.
            </p>
          </Item>
          <Item>
            <img src={person} alt="" />
            <h2>dać odczuć</h2>
            <p>
              osobie atakowanej, że <br /> nie jest sama.
            </p>
          </Item>
          <Item>
            <img src={Hand} alt="" />
            <h2>uchronić</h2>
            <p>
              {" "}
              przed dalszym
              <br /> atakiem.
            </p>
          </Item>
        </Grid>
      </Container>
    </Wrapper>
  );
};

export default SectionB;
