import React from "react";
import styled from "styled-components";
import media from "../../media";
const Wrapper = styled.div`
  background: ${({ theme }) => theme.colors.grey3};
  width: 100vw;
  height: 50px;
`;

const NavItems = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  p {
    font-family: "Roboto-Regular";
    text-transform: uppercase;
    margin: 0 8px;
    font-size: 12px;
    position: relative;

    &::after {
      content: "";
      position: absolute;
      top: -2px;
      margin-left: 8px;
      height: 22px;
      border-right: 2px solid #c6c6c6;
    }
  }
  p:nth-child(3) {
    &::after {
      content: "";
      border-right: none;
    }
  }

  ${media.tablet`
  p {
      margin: 0 30px;
      font-size: 14px;
    :hover {
      cursor: pointer;
    }
      &::after {
        right: -30px;
      }
  }
  `}

  a {
    color: black;

    :hover {
      color: black;
      text-decoration: none;
      cursor: pointer;
    }
  }
`;
const FooterNavBar = ({ toggle }) => {
  return (
    <Wrapper>
      <NavItems>
        <a
          href="http://lambdawarszawa.org/lambdawarszawa/o-nas/wppolityka-prywatnosci/"
          target="_blank"
        >
          <p>polityka prywatności</p>
        </a>
        <a
          href="http://lambdawarszawa.org/lambdawarszawa/kontakt/"
          target="_blank"
        >
          <p>kontakt</p>
        </a>

        <p onClick={() => toggle()}>dla mediów</p>
      </NavItems>
    </Wrapper>
  );
};

export default FooterNavBar;
