import React from "react";
import styled from "styled-components";
import media from "../../media";
import { Container } from "reactstrap";
import logo from "../../img/footer/logo.png";
import logo2 from "../../img/footer/logo2.svg";
import logo3 from "../../img/footer/logo3.png";
import logo4 from "../../img/footer/logo4.png";
import logo5 from "../../img/footer/logo5.png";
import logo6 from "../../img/footer/logo6.png";
import logo7 from "../../img/footer/logo7.png";
import logo8 from "../../img/footer/logo8.png";
import logo9 from "../../img/footer/logo9.png";

const Wrapper = styled.div`
  background: white;
  padding-top: 20px;
  width: 100vw;
  min-height: 100%;
  font-family: "Roboto-Regular";
  font-size: 14px;
`;
const Content = styled.div`
  text-align: center;
  position: relative;
  ${media.large`
  display: flex;
  justify-content: center;
  text-align: left;
  padding-bottom: 20px;
    
    p {
        padding-left: 10px;
    }
   
  `}

  &::after {
    content: "";
    position: absolute;
    bottom: -12px;
    left: 0;
    margin-left: 8px;
    width: 100%;
    border-bottom: 3px solid #cdcdcd;
  }
`;
const LeftWrapper = styled.div`
  ${media.large`
    position: relative;
    &::after {
     content: "";
    position: absolute;
      top: -2px;
      right: 0;
      margin-left: 8px;
      height: 90px;
      border-right: 1px solid #c6c6c6;
    }
}

`}

  ${media.huge`
padding-right: 15px;
`}
`;

const RightWrapper = styled.div`
  padding-top: 10px;

  img {
      margin-top: 10px;
  }

  ${media.tablet`
  padding-top: 20px;
  `}
  ${media.large`
  padding: 0;
  `}
  ${media.huge`
    padding-left: 15px;
`}
`;

const Logos = styled.div`
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  img {
    height: 30px;
  }

  ${media.tablet`
  justify-content: center;
  img {
      margin: 0 10px;
  }
  `}
`;
const LastText = styled.p`
  text-align: center;
  font-size: 14px;
  padding: 25px 0;
  margin: 0;
`;
const Sponsors = () => {
  return (
    <Wrapper>
      <Container>
        <Content>
          <LeftWrapper>
            <p>Organizatorzy:</p>
            <Logos>
              <img src={logo} alt="" />
              <img src={logo2} alt="" />
              <img src={logo3} alt="" />
            </Logos>
          </LeftWrapper>
          <RightWrapper>
            <p>Wspierają nas:</p>
            <Logos>
              <img src={logo4} alt="" />
              <img src={logo5} alt="" />
              <img src={logo6} alt="" />
              <img src={logo7} alt="" />
              <img src={logo8} alt="" />
              <img src={logo9} alt="" />
            </Logos>
          </RightWrapper>
        </Content>
        <LastText>©Stowarzyszenie Lambda Warszawa 2019</LastText>
      </Container>
    </Wrapper>
  );
};

export default Sponsors;
