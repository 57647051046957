import React, { useState } from "react";
import styled from "styled-components";
import media from "../../media";
import { Button, Form, FormGroup, Input, Alert } from "reactstrap";
import { Container } from "reactstrap";
import axios from "axios";

const Wrapper = styled.div`
  padding: 2em 0 1em 0;
  background: ${({ theme }) => theme.colors.grey2};
  position: relative;
`;
const MainTitle = styled.div`
  font-family: "Bebas Neue Bold";
  text-align: center;
  font-size: 30px;
  padding-bottom: 30px;
  span {
    color: ${({ theme }) => theme.colors.darkGreen};
  }

  ${media.tablet`
  font-size: 40px;

  `}

  ${media.large`
  font-size: 55px;
  `}
`;
const QuizComponent = styled.div`
  height: 100%;
  width: 100%;
  min-height: 200px;

  display: flex;
  justify-content: space-around;
  flex-direction: column;

  i {
    font-size: 30px;

    :hover {
      cursor: pointer;
    }
  }

  ${media.tablet`
  i {
    font-size: 40px;
  }
  `}
`;

const YesOrNoButtons = styled.div`
  /* position: absolute; */
  /* top: 80%; */
  margin: 0 auto;
  transform: translateY(-50%);
  display: flex;

  div {
    font-family: "Bebas Neue Bold";
    margin: 0 10px;
    border-radius: 50%;
    font-size: 1.5em;
    height: 60px;
    width: 60px;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;

    :hover {
      cursor: pointer;
    }

    span {
      margin-top: 7px;
    }
  }

  div:nth-child(1) {
    background: ${({ theme }) => theme.colors.darkGreen};
  }
  div:nth-child(2) {
    background: ${({ theme }) => theme.colors.red};
  }

  ${media.tablet`
  div {
  height: 80px;
  width: 80px;
  font-size: 35px;
  }
  `}

  ${media.large`
  div {
    height: 100px;
  width: 100px;
  }
  `}
`;

const Question = styled.div`
  font-family: "Bebas Neue Bold";
  text-align: center;
  font-size: 1em;
  padding: 1em 0 3em 0;
  /* 
  i {
    position: absolute;
    top: 100%;
    transform: translateY(-50%);
    left: 10%;
    font-size: 30px;
    :hover {
      cursor: pointer;
    }
  } */
  ${media.tablet`
  font-size: 1.4em;

  i {
    left: 20%;
    font-size: 40px;
  }
  `}
`;

// INPUT STYLES

const InputStyled = styled(Input)`
  min-height: 100px;
`;

const ButtonStyled = styled(Button)`
  width: 100%;
  background: ${({ theme }) => theme.colors.orange};
  border-radius: 0.25rem;
  border: 0;
  margin-bottom: 30px;

  :hover {
    background: ${({ theme }) => theme.colors.orange};
  }
`;

const Quiz = () => {
  const [inputValue, inputValueChange] = useState("");
  const [inputError, setInputError] = useState({ message: "" });
  const [currentGraph, changeCurrentGraph] = useState({
    id: "q1",
    question: "Widzisz przemoc. Czy osoba atakowana jest fizycznie zagrożona?",
    yes: "q2",
    no: "q3",
    end: false,
    prev: null
  });

  // BIG LIST !!!
  const [graph, changeGraph] = useState([
    {
      id: "q1",
      question:
        "Widzisz przemoc. Czy osoba atakowana jest fizycznie zagrożona?",
      yes: "q2",
      no: "q3",
      end: false,
      prev: null
    },

    // g2 YES LINE
    {
      id: "q2",
      question: "Czy masz możliwość uwolnić ją z tej sytuacji?",
      yes: "q2_1",
      no: "q2_0",
      end: false,
      prev: "q1"
    },
    {
      id: "q2_1",
      question: "Czy MOŻESZ odejść z nią w inne miejsce?",
      yes: "q2_11",
      no: "q2_10",
      end: false,
      prev: "q2"
    },

    {
      id: "q2_11",
      question: "CZY W MIEJSCU, DO KTÓREGO PÓJDZIECIE, SĄ INNE OSOBY?",
      yes: "q2_111",
      no: "q2_110",
      end: false,
      prev: "q2_1"
    },
    {
      id: "q2_111",
      question:
        "ODEJDŹ Z OSOBĄ W BEZPIECZNE MIEJSCE, WYSIĄDŹ Z NIĄ Z AUTOBUSU, WYJDŹ Z NIĄ Z POMIESZCZENIA.",
      question2:
        "NASTĘPNIE: zaproponuj odprowadzenie do domu, zaoferuj pomoc w zgłoszeniu na Policję lub innym osobom (pracodawcy), zapewnij, że złożysz zeznania, wskaż kontakt do organizacji pozarządowej (na przykład Telefon Zaufania Lambdy Warszawa). Spytaj, co możesz jeszcze zrobić.",
      end: true,
      prev: "q2_11"
    },
    // G2 NO LINE
    {
      id: "q2_0",
      question:
        "CZY MASZ MOŻLIWOŚĆ, BY ZGŁOSIĆ SYTUACJĘ INNYM OSOBOM/SŁUŻBOM/POLICJI?",
      yes: "q2_01",
      no: "q2_00",
      end: false,
      prev: "q2"
    },
    {
      id: "q2_00",
      question:
        "POPROŚ JAKĄŚ OSOBĘ, KTÓRA RÓWNIEŻ JEST ŚWIADKIEM TEJ SYTUACJI, LUB OSOBĘ ZNAJOMĄ PRZEZ SMS (LUB KOMUNIKATOR), ABY TO ZROBIŁA",
      end: true,
      prev: "q2_0"
    },
    {
      id: "q2_01",
      question:
        "ZGŁOŚ SYTUACJĘ KIEROWCY, OCHRONIE, WŁAŚCICIELOWI SKLEPU, PRZEŁOŻONEMU, POLICJI.",
      end: true,
      prev: "q2_0"
    },
    // G2 10 NO LINE
    {
      id: "q2_10",
      question:
        "CZY MOŻESZ ODDZIELIĆ OSOBĘ OD SPRAWCY - NA PRZYKŁAD POPRZEZ STANIĘCIE MIĘDZY NIĄ A SPRAWCĄ LUB ZWIĘKSZENIE DYSTANSU MIĘDZY NIMI?",
      yes: "q2_101",
      no: "q2_100",
      end: false,
      prev: "q2_1"
    },
    {
      id: "q2_100",
      question:
        "ODEJDŹ I ZADZWOŃ PO POMOC LUB SKONTAKTUJ SIĘ Z OSOBĄ, KTORA MOŻE TO ZROBIĆ.",
      end: true,
      prev: "q2_10"
    },
    // G2 10 YES LINE
    {
      id: "q2_101",
      question:
        "CZY Z ZACHOWANIA SPRAWCY WNIOSKUJESZ, ŻE MOŻE TO SPOWODOWAĆ NASILENIE ATAKU NA OSOBĘ POKRZYWDZONĄ LUB CIEBIE?",
      yes: "q2_1011",
      no: "q2_1010",
      end: false,
      prev: "q2_10"
    },
    {
      id: "q2_1011",
      question: "CZY MOŻESZ POPROSIĆ INNE OBECNE OSOBY O WSPARCIE?",
      yes: "q2_10111",
      no: "q2_10110",
      end: false,
      prev: "q2_101"
    },
    {
      id: "q2_10111",
      question:
        "ZAPROPONUJ INNYM OSOBOM, ABY WSPÓLNIE Z TOBĄ ODDZIELIŁY OSOBĘ POKRZYWDZONĄ OD SPRAWCY.",
      end: true,
      prev: "q2_1011"
    },
    {
      id: "q2_10110",
      question:
        "ODEJDŹ I ZADZWOŃ PO POMOC LUB SKONTAKTUJ SIĘ Z OSOBĄ, KTÓRA MOŻE TO ZROBIĆ.",
      end: true,
      prev: "q2_1011"
    },
    // q2_101 NO LINE
    {
      id: "q2_1010",
      question:
        "STAŃ POMIĘDZY OSOBĄ ATAKOWANĄ A SPRAWCĄ LUB ZWIĘKSZ DYSTANS POMIĘDZY NIMI ",
      question2:
        "Uważaj! Jeśli sprawca znajduje się za Tobą, postaraj się móc kontrolować jego zachowanie. Jednocześnie zwracaj się do osoby atakowanej – to z nią rozmawiaj, pytaj, czego potrzebuje i co możesz dla niej zrobić. ",
      end: true,
      prev: "q2_101"
    },
    // q2 11 LINE
    {
      id: "q2_110",
      question: "CZY SPRAWCA MOŻE ZA WAMI PÓJŚĆ?",
      yes: "q2_1101",
      no: "q2_1100",
      end: false,
      prev: "q2_11"
    },
    {
      id: "q2_1101",
      question:
        "ZOSTAŃCIE W MIEJSCU, W KTÓRYM SĄ INNE OSOBY. WYBIERZ MIEJSCE, W KTÓRYM BĘDĄ INNE OSOBY LUB ODEJDŹ I ZADZWOŃ PO POMOC.",
      question2:
        "NASTĘPNIE: zaproponuj odprowadzenie do domu, zaoferuj pomoc w zgłoszeniu na Policję lub innym osobom (pracodawcy), zapewnij, że złożysz zeznania, wskaż kontakt do organizacji pozarządowej (na przykład Telefon Zaufania Lambdy Warszawa). Spytaj, co możesz jeszcze zrobić.",
      end: true,
      prev: "q2_110"
    },
    {
      id: "q2_1100",
      question:
        "ODEJDŹ Z OSOBĄ W BEZPIECZNE MIEJSCE, WYSIĄDŹ Z NIĄ Z AUTOBUSU, WYJDŹ Z NIĄ Z POMIESZCZENIA.",
      question2:
        "NASTĘPNIE: zaproponuj odprowadzenie do domu, zaoferuj pomoc w zgłoszeniu na Policję lub innym osobom (pracodawcy), zapewnij, że złożysz zeznania, wskaż kontakt do organizacji pozarządowej (na przykład Telefon Zaufania Lambdy Warszawa). Spytaj, co możesz jeszcze zrobić.",
      end: true,
      prev: "q2_110"
    },

    // q3 LINE START

    {
      id: "q3",
      question: "CZY TA SYTUACJA MA MIEJSCE W DOMU/RODZINIE?",
      yes: "q3_1",
      no: "q3_0",
      end: false,
      prev: "q1"
    },

    // q3 YES LINE
    {
      id: "q3_1",
      question: `WYRAŹ SWOJĄ NIEZGODĘ NA TAKIE ZACHOWANIE SPRAWCY, KOMENTARZE ITP.`,
      question2: `NAWIĄŻ KONTAKT WZROKOWY LUB SŁOWNY Z OSOBĄ, OKAŻ JEJ WSPARCIE.  `,
      question3: `SPRÓBUJ OPUŚCIĆ POMIESZCZENIE, SUGERUJĄC OSOBIE, ŻE MOŻE WYJŚĆ Z TOBĄ.  `,
      question4: `POROZMAWIAJ Z OSOBĄ, SPYTAJ, CZEGO POTRZEBUJE.  `,
      question5: `PODAJ NUMER TELEFONU ZAUFANIA LAMBDY WARSZAWA. `,
      end: true,
      prev: "q3"
    },

    // q3 NO LINE
    {
      id: "q3_0",
      question: "CZY TA SYTUACJA MA MIEJSCE W INTERNECIE?",
      yes: "q3_01",
      no: "q3_00",
      end: false,
      prev: "q3"
    },
    {
      id: "q3_00",
      question: "CZY TA SYTUACJA DZIEJE SIĘ W MIEJSCU PUBLICZNYM?",
      yes: "q3_001",
      no: "q3_000",
      end: false,
      prev: "q3_0"
    },
    {
      id: "q3_000",
      question: "CZY TA SYTUACJA MA MIEJSCE W PRACY?",
      yes: "q3_0001",
      no: "q3_0000",
      end: false,
      prev: "q3_00"
    },
    {
      id: "q3_0000",
      end: true
    },
    //  g3_01 YES LINE
    {
      id: "q3_01",
      question: "CZY PRZEMOC JEST SKIEROWANA DO KONKRETNEJ OSOBY?",
      yes: "q3_011",
      no: "q3_010",
      end: false,
      prev: "q3_0"
    },
    {
      id: "q3_011",
      question: "CZY PRZEMOCOWA TREŚĆ NAWOŁUJE DO PRZESTĘPSTWA?",
      yes: "q3_0111",
      no: "q3_0110",
      end: false,
      prev: "q3_01"
    },
    {
      id: "q3_0111",
      question: "ZGŁOŚ SYTUACJĘ NA POLICJĘ.",
      end: true,
      prev: "q3_011"
    },
    //  g3_01 NO LINE
    {
      id: "q3_010",
      question: "CZY TEJ OSOBIE GROZI BEZPOŚREDNIE NIEBEZPIECZEŃSTWO?",
      yes: "q3_0101",
      no: "q3_0100",
      end: false,
      prev: "q3_01"
    },
    {
      id: "q3_0100",
      question: "zgłoś komentarz/treść FB.",
      end: true,
      prev: "q3_010"
    },
    {
      id: "q3_0110",
      question: "zgłoś komentarz/treść FB.",
      end: true,
      prev: "q3_011"
    },

    // 0101 LINE
    {
      id: "q3_0101",
      question: "CZY ZNASZ TĘ OSOBĘ LUB MOŻESZ SIĘ Z NIĄ SKONTAKTOWAĆ?",
      yes: "q3_01011",
      no: "q3_01010",
      end: false,
      prev: "q3_010"
    },
    {
      id: "q3_01011",
      question:
        "NAPISZ LUB ZADZWOŃ DO TEJ OSOBY, OKAŻ JEJ WSPARCIE, ZAPROPONUJ POMOC W KONTAKCIE Z POLICJĄ. ",
      question2: "SPYTAJ, CZEGO POTRZEBUJE.",
      question3: "ZGŁOŚ KOMENTARZ.",
      question4:
        "POINFORMUJ O DZIAŁANIU ORGANIZACJI POZARZĄDOWYCH (na przykład o Telefonie Zaufania Lambdy Warszawa). ",
      question5: "ZGŁOŚ DO LAMBDY WARSZAWA. ",
      end: true,
      prev: "q3_0101"
    },
    {
      id: "q3_01010",
      question: "ZGŁOŚ SYTUACJĘ ODPOWIEDNIM OSOBOM/SŁUŻBOM.",
      end: true,
      prev: "q3_0101"
    },
    // q3_001 LINE
    {
      id: "q3_001",
      question: "CZY CZUJESZ SIĘ NA SIŁACH, BY ZAREAGOWAĆ SAMODZIELNIE?",
      yes: "q3_0011",
      no: "q3_0010",
      end: false,
      prev: "q3_00"
    },
    {
      id: "q3_0011",
      question:
        "POWIEDZ, ŻE NIE GODZISZ SIĘ NA TAKIE ZACHOWANIE, KOMENTARZE ITP. ",
      question2:
        'UNIKAJ SFORMUŁOWAŃ "ZOSTAW GO/JĄ", NIE PYTAJ SPRAWCY, DLACZEGO TAK MÓWI. NIE "BROŃ" OSOBY ATAKOWANEJ, BO MOŻE TO SPOTĘGOWAĆ AGRESJĘ SPRAWCY WOBEC NIEJ.  ',
      question3:
        "WYRAŹ SWOJĄ NIEZGODĘ NA ZACHOWANIE SPRAWCY, ALE SIĘ NIM NIE ZAJMUJ. ",
      question4:
        "ZACZNIJ ROZMOWĘ Z OSOBĄ ATAKOWANĄ, SPYTAJ, CZEGO POTRZEBUJE, OKAŻ JEJ WSPARCIE, ZAPROPONUJ OPUSZCZENIE MIEJSCA. ",
      end: true,
      prev: "q3_001"
    },
    {
      id: "q3_0010",
      question:
        "ZWRÓĆ SIĘ DO INNEJ OSOBY LUB OSÓB, ZAPROPONUJ WSPÓLNĄ REAKCJĘ NIEZGODY NA ZACHOWANIE SPRAWCY. ",
      question2:
        ' UNIKAJ SFORMUŁOWAŃ "ZOSTAW GO/JĄ", NIE PYTAJ SPRAWCY, DLACZEGO TAK MÓWI, NIE "BROŃ" OSOBY ATAKOWANEJ, BO MOŻE TO SPOTĘGOWAĆ AGRESJĘ SPRAWCY WOBEC NIEJ.  ',
      question2:
        " ZACZNIJ ROZMOWĘ Z OSOBĄ ATAKOWANĄ, SPYTAJ, CZEGO POTRZEBUJE, OKAŻ JEJ WSPARCIE, ZAPROPONUJ WSPÓLNE OPUSZCZENIE MIEJSCA.",
      end: true,
      prev: "q3_001"
    },

    // q3_0001 LINE
    {
      id: "q3_0001",
      question:
        "CZY ZACHOWANIE SPRAWCY MA CHARAKTER DYSKRYMINACJI LUB MOBBINGU?",
      yes: "q3_00011",
      no: "q3_00010",
      end: false,
      prev: "q3_000"
    },
    {
      id: "q3_00011",
      question:
        "CZY W MIEJSCU PRACY OBOWIĄZUJE PROCEDURA ANTYMOBBINGOWA/ANTYDYSKRYMINACYJNA?",
      yes: "q3_000111",
      no: "q3_000110",
      end: false,
      prev: "q3_0001"
    },
    {
      id: "q3_000111",
      question:
        "ZAPROPONUJ OSOBIE ATAKOWANEJ ZGŁOSZENIE DO ODPOWIEDNIEGO ORGANU ",
      question2: "LUB ZA ZGODĄ OSOBY ZGŁOŚ ZACHOWANIE SPRAWCY.  ",
      question3:
        "SPRÓBUJ OPUŚCIĆ POMIESZCZENIE, SUGERUJĄC OSOBIE, ŻE MOŻE WYJŚĆ Z TOBĄ. ",
      question4:
        "POROZMAWIAJ Z OSOBĄ – SPYTAJ, CZEGO POTRZEBUJE, PODAJ NUMER TELEFONU ZAUFANIA LAMBDY WARSZAWA.  ",
      end: true,
      prev: "q3_00011"
    },
    {
      id: "q3_00010",
      question:
        "WYRAŹ SWOJĄ NIEZGODĘ NA TAKIE ZACHOWANIE SPRAWCY, KOMENTARZE ITP. ",
      question2:
        "NAWIĄŻ KONTAKT WZROKOWY LUB SŁOWNY Z OSOBĄ, OKAŻ JEJ WSPARCIE. ",
      question3:
        "SPRÓBUJ OPUŚCIĆ POMIESZCZENIE, SUGERUJĄC OSOBIE, ŻE MOŻE WYJŚĆ Z TOBĄ.   ",
      question4: "POROZMAWIAJ Z OSOBĄ – SPYTAJ, CZEGO POTRZEBUJE.  ",
      question5: "PODAJ NUMER TELEFONU ZAUFANIA LAMBDY WARSZAWA. ",
      end: true,
      prev: "q3_0001"
    },
    {
      id: "q3_000110",
      question:
        "WYRAŹ SWOJĄ NIEZGODĘ NA TAKIE ZACHOWANIE SPRAWCY, KOMENTARZE ITP. ",
      question2:
        "NAWIĄŻ KONTAKT WZROKOWY LUB SŁOWNY Z OSOBĄ, OKAŻ JEJ WSPARCIE. ",
      question3:
        "SPRÓBUJ OPUŚCIĆ POMIESZCZENIE, SUGERUJĄC OSOBIE, ŻE MOŻE WYJŚĆ Z TOBĄ.   ",
      question4: "POROZMAWIAJ Z OSOBĄ – SPYTAJ, CZEGO POTRZEBUJE.  ",
      question5: "PODAJ NUMER TELEFONU ZAUFANIA LAMBDY WARSZAWA. ",
      end: true,
      prev: "q3_00011"
    },
    {
      id: "SendSucces",
      end: true
    }
  ]);

  const filterFunction = id => {
    graph.map(el => {
      if (el.id === id) return changeCurrentGraph(el);
    });
  };

  const ButtonYes = () => {
    filterFunction(currentGraph.yes);
  };

  const ButtonNo = () => {
    filterFunction(currentGraph.no);
  };

  const showButtonOrNo = () => {
    if (currentGraph.end === false) {
      return (
        <>
          <div onClick={ButtonYes}>
            {" "}
            <span>TAK </span>{" "}
          </div>
          <div onClick={ButtonNo}>
            {" "}
            <span> NIE </span>{" "}
          </div>
        </>
      );
    } else {
      return (
        <div onClick={() => filterFunction("q1")}>
          <i className="fas fa-redo" />
        </div>
      );
    }
  };

  const showQuestionOrForm = () => {
    if (currentGraph.id === "q3_0000") {
      return (
        <>
          <p>
            Nie przewidzieliśmy takiej sytuacji. Opisz ją i prześlij do nas.
            Możesz też zadzwonić na telefon zaufania, żeby porozmawiać na ten
            temat
            <br /> - 22 628 52 22 (od poniedziałku do piątku, w godzinach 18.00
            - 21.00)
          </p>
          <Form onSubmit={submitHandler}>
            <FormGroup>
              {inputError.message && (
                <Alert color="danger">{inputError.message}</Alert>
              )}

              <InputStyled
                type="textarea"
                name="text"
                id="exampleText"
                onChange={e => inputValueChange(e.target.value)}
                invalid={inputError.message ? true : false}
              />
            </FormGroup>
            <ButtonStyled>Wyślij</ButtonStyled>
          </Form>
        </>
      );
    }
    if (currentGraph.id === "SendSucces") return <p>Wysłano, dziękujemy !!!</p>;
    return (
      <>
        <p>{currentGraph.question}</p>
        <p>{currentGraph.question2 && currentGraph.question2}</p>
        <p>{currentGraph.question3 && currentGraph.question3}</p>
      </>
    );
  };

  const submitHandler = e => {
    e.preventDefault();

    // CHECK IF FORMTEXT IT IS NOT EMPTY

    if (inputValue.length === 0)
      return setInputError({
        message: "Nie mozesz zostawić tego pola pustego"
      });

    // SEND FORM BY AXIOS

    axios({
      method: "post",
      url: "/save.php",
      data: {
        text: inputValue
      }
    })
      .then(res => {
        setInputError({ message: "" });
        filterFunction("SendSucces");
      })
      .catch(err => console.log(err));
  };
  return (
    <Wrapper name="Quiz">
      <Container>
        <MainTitle>
          Kliknij i <span>dowiedz się, jak konkretnie zareagować</span>
        </MainTitle>
        <QuizComponent>
          <Question>{showQuestionOrForm()}</Question>
          {currentGraph.prev && (
            <i
              className="fas fa-arrow-circle-left"
              onClick={() => filterFunction(currentGraph.prev)}
            />
          )}
          <YesOrNoButtons>{showButtonOrNo()}</YesOrNoButtons>
        </QuizComponent>
      </Container>
    </Wrapper>
  );
};

export default Quiz;
