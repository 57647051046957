import React, { Component } from "react";
import styled from "styled-components";
import media from "../../media";
import { Collapse, Navbar, Nav, Container } from "reactstrap";
import navLogo2 from "../../img/navLogo2.png";
import hambWhite from "../../img/hambWhite.svg";
import { Link, animateScroll as scroll } from "react-scroll";

const NavBarStyled = styled(Navbar)`
  transition: 0.5s;
  position: fixed;
  top: 0;
  width: 100%;
  padding-top: ${({ isTop }) => (isTop ? "5px" : "10px")};
  background: ${({ isTop }) => (isTop ? "#212529" : "transparent")} !important;
  font-family: "Bebas Neue Bold";
  z-index: 999;
`;

const Logo = styled.img`
  transition: 0.5s;
  height: ${({ isTop }) => (isTop ? "40px" : "60px")};
  ${media.tablet`
  margin-left: 50px;
  `}

  ${media.nav`
  height: ${({ isTop }) => (isTop ? "50px" : "79px")};
  `}

  :hover {
    cursor: pointer;
  }
`;

const NavStyled = styled(Nav)`
  padding: 0 0 0 10px;

  li {
    padding: 6px 0;
    text-transform: uppercase;
    color: white;
    font-size: 21px;

    :hover {
      cursor: pointer;
    }

    i {
      padding: 0 5px;

      :hover {
        cursor: pointer;
      }
    }

    a {
      color: white;
      :hover {
        color: white;
      }
    }
  }

  ${media.nav`
    min-width: 100%;

    display: flex;
    justify-content: space-around;
  `}
`;

const Hamb = styled.img`
  ${media.nav`
      display: none;
      `}
`;

export class NavBarComponent extends Component {
  state = {
    isOpen: false,
    isTop: false
  };
  componentDidMount() {
    document.addEventListener("scroll", () => {
      const isTop = window.scrollY > 10;
      if (isTop !== this.state.isTop) {
        this.setState({ isTop });
      }
    });
  }

  toggle = () => {
    if (window.innerWidth < 992) {
      return this.setState({
        isOpen: !this.state.isOpen,
        isTop: true
      });
    }
  };

  scrollToTop = () => {
    scroll.scrollToTop();
  };

  whatPageIsIthandler = () => {};

  render() {
    const { isTop } = this.state;
    return (
      <NavBarStyled isTop={isTop} color="light" light expand="lg">
        <Container>
          <Logo
            isTop={isTop}
            src={navLogo2}
            alt=""
            onClick={() => this.scrollToTop()}
          />
          <Hamb src={hambWhite} alt="" onClick={this.toggle} />
          <Collapse isOpen={this.state.isOpen} navbar>
            <NavStyled navbar>
              <Link
                to="sectionA"
                smooth
                offset={-70}
                onClick={() => this.toggle()}
              >
                <li>Twoja reakcja</li>
              </Link>
              <Link
                to="sectionB"
                smooth
                offset={270}
                onClick={() => this.toggle()}
              >
                <li>Zanim zareagujesz</li>
              </Link>
              <Link
                to="sectionE"
                smooth
                offset={20}
                onClick={() => this.toggle()}
              >
                <li>Jak reagować</li>
              </Link>
              <Link
                to="sectionF"
                smooth
                // offset={-70}
                onClick={() => this.toggle()}
              >
                <li>Dla Firm</li>
              </Link>
              <Link to="Footer" smooth onClick={() => this.toggle()}>
                <li>Wesprzyj kampanię</li>
              </Link>
              <li>
                <a
                  href="https://www.facebook.com/lambda.warszawa"
                  target="_blank"
                >
                  <i className="fab fa-facebook-f" />
                </a>
                <a
                  href="https://www.instagram.com/lambdawarszawa/"
                  target="_blank"
                >
                  <i className="fab fa-instagram" />
                </a>
                <a href="https://twitter.com/lambda_warszawa" target="_blank">
                  <i className="fab fa-twitter" />
                </a>
              </li>
            </NavStyled>
          </Collapse>
        </Container>
      </NavBarStyled>
    );
  }
}

export default NavBarComponent;
