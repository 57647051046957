import React, { useState } from "react";
import styled from "styled-components";
import media from "../../media";
import { Container } from "reactstrap";
import Tile from "./Tile";
import FooterNavBar from "./FooterNavBar";
import Sponsors from "./Sponsors";
import Contact from "../Contact/Contact";

const Wrapper = styled.div`
  background: ${({ theme }) => theme.colors.grey2};
  height: 100%;

  h2 {
    padding: 40px 0 10px 0;
    text-align: center;
    font-size: 2.5em;
    font-family: "Bebas Neue Bold";
  }

  ${media.tablet`
  h2 {
      font-size: 3em;
  }
  `}
`;
const Donate = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
`;

const TextDonate = styled.div`
  padding-top: 20px;
  text-align: center;
  font-family: "Roboto-Regular";

  p {
    font-size: 0.8em;
  }

  p:nth-child(1) {
    margin: 5px 0;
  }

  ${media.tablet`
  p {
     font-size: 1em;
  }
  `}
`;

const RobotoBold = styled.span`
  font-family: "Roboto-Bold";
`;

const LittleDonateInfo = styled.p`
  margin: 5px 0 10px 0;
  text-align: 14px;
  font-family: "Roboto-Regular";
  text-align: center;

  ${media.tablet`
  font-size: 14px;
  `}
`;
const FooterNew = () => {
  const [show, showToggle] = useState(false);

  return (
    <Wrapper name="Footer">
      <Container>
        <h2>Wesprzyj kampanię</h2>
        <LittleDonateInfo>
          Kampania na celu ma dotarcie do jak największej liczby osób, które są
          skłonne do reagowania na przemoc. <br />
          Wesprzyj nas, przekazując darowiznę!
        </LittleDonateInfo>
        <Donate>
          <a
            href="https://ssl.dotpay.pl/?pid=2AMTBP5PVN5ZX66XVISDG73PXRYJHYMY"
            target="_blank"
          >
            <Tile price={"10 PLN"} color={"#1a8f3b"} />
          </a>
          <a
            href="https://ssl.dotpay.pl/?pid=1XRRSSEF3VRG1A3I871713QF3HT2H4FH"
            target="_blank"
          >
            <Tile price={"20 PLN"} color={"#ffa300"} />
          </a>
          <a
            href="https://ssl.dotpay.pl/?pid=8UASGSZPNZLDQ2TIKIVZ2GQVLBA7JBVS"
            target="_blank"
          >
            <Tile price={"50 PLN"} color={"#1a8f3b"} />
          </a>
          <a
            href="https://ssl.dotpay.pl/?pid=QL5X22VV2RZ9JETHG35TXP5Y8A3QMMPV"
            target="_blank"
          >
            <Tile price={"60 PLN"} color={"#ffa300"} />
          </a>
          <a
            href="https://ssl.dotpay.pl/?pid=45MYIWMWX5PAIQ799YWX9HVF9G9RX78U"
            target="_blank"
          >
            <Tile price={"100 PLN"} color={"#1a8f3b"} />
          </a>
          <a
            href="https://ssl.dotpay.pl/?pid=AY76QLZI6MWX8TMDFG4JSPEJWD2MU86U"
            target="_blank"
          >
            <Tile price={"150 PLN"} color={"#ffa300"} />
          </a>
          <a href="https://ssl.dotpay.pl/payment/index.php" target="_blank">
            <Tile price={"Inna Kwota"} color={"#1a8f3b"} />
          </a>
        </Donate>
        <TextDonate>
          <p>
            <RobotoBold>
              Możesz również wykonać tradycyjny przelew bankowy:{" "}
            </RobotoBold>
          </p>
          <p>
            Stowarzyszenie Lambda Warszawa, ul. Żurawia 24A, 00-515 Warszawa{" "}
            <br />
            Numer konta: 47213000042001025987380001 (IBAN: dodaj PL przed
            numerem konta) <br />
            SWIFT (BIC) VOWAPLP1 <br />
            Tytuł przelewu: <RobotoBold> darowizna. </RobotoBold>
          </p>
        </TextDonate>
      </Container>
      <FooterNavBar toggle={() => showToggle(!show)} />
      <Contact show={show} />
      <Sponsors />
    </Wrapper>
  );
};

export default FooterNew;
