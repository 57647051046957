import React from "react";
import styled from "styled-components";
import ContactInfo from "./ContactInfo";
import DownloadFiles from "./DownloadFiles";

const Wrapper = styled.div`
  height: ${({ show }) => (show ? "auto" : "0")};
  opacity: ${({ show }) => (show ? "1" : "0")};
  overflow: hidden;
  transition: 1s;
`;

const Contact = props => {
  return (
    <Wrapper show={props.show}>
      <ContactInfo />
      <DownloadFiles />
    </Wrapper>
  );
};

export default Contact;
