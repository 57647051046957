import React, { useState } from "react";
import styled from "styled-components";
import media from "../../media";
import { Container } from "reactstrap";
import { Popover, PopoverBody } from "reactstrap";

const Wrapper = styled.div`
  padding: 3em 0 1em 0;
  background: ${({ theme }) => theme.colors.grey2};
`;
const Text = styled.div`
  text-align: center;

  h2 {
    font-family: "Bebas Neue Bold";
    font-size: 39px;
  }

  p {
    font-family: "Roboto-Regular";
    font-size: 0.8em;
    color: "#010101";

  }

  ${media.tablet`
  padding: 0 7em;
  `}

  ${media.large`
  padding: 0 5em;

  h2 {font-size: 64px;}
  p {
    font-size: 18px;
    line-height: 25px;
  }
  `}

  ${media.huge`
  padding: 0 10em;
  `}
`;

const DarkGreen = styled.span`
  color: ${({ theme }) => theme.colors.darkGreen};
`;

const RobotoBold = styled.span`
  font-family: "Roboto-Bold";
`;

const PopOverInfo = styled.span`
  text-decoration: underline;

  :hover {
    cursor: pointer;
  }
`;

const SectionA = () => {
  const [popoverOpen, togglePopover] = useState(false);

  const toggle = () => {
    togglePopover(!popoverOpen);
  };
  return (
    <Wrapper name="sectionA">
      <Container>
        <Text>
          <h2>
            TWOJA REAKCJA MA <DarkGreen>MOC </DarkGreen>{" "}
          </h2>
          <p>
            <RobotoBold> 68,9% osób LGBT+</RobotoBold> doświadczyło przemocy
            motywowanej uprzedzeniami w ciągu dwóch lat poprzedzających{" "}
            <PopOverInfo id="Popover1" onMouseOver={toggle} onMouseOut={toggle}>
              badanie
            </PopOverInfo>
            . Agresja wobec osób homoseksualnych, biseksualnych i transpłciowych
            jest bardzo częsta. Gdy jesteś jej świadkiem,
            <RobotoBold> PO PROSTU REAGUJ.</RobotoBold>
          </p>
          <Popover placement="top" isOpen={popoverOpen} target="Popover1">
            <PopoverBody>
              Raport „Sytuacja społeczna osób LGBTA w Polsce 2015-2016” (Świder
              i Winiewski 2017).
            </PopoverBody>
          </Popover>
          {/* <p>
          Zastanawiasz się czasem,{" "}
          <RobotoBold> jaką siłę naprawdę ma reagowanie </RobotoBold> i ile może
          zmienić? Twoja reakcja to Twoja moc specjalna: może powstrzymać
          przemoc; zachęcić innych do zaangażowania się; zwracać uwagę na to, co
          jest nie do przyjęcia. Zobacz, ile możesz.{" "}
          <RobotoBold> Twoja reakcja jest ważna. </RobotoBold>
        </p> */}
          {/* <LittleInfo>
          * Raport „Sytuacja społeczna osób LGBTA w Polsce 2015-2016” (Świder i
          Winiewski 2017).
        </LittleInfo> */}
        </Text>
      </Container>
    </Wrapper>
  );
};

export default SectionA;
