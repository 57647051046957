import React from "react";
import styled from "styled-components";
import media from "../../media";
import profil from "../../img/media/profil.png";

const Wrapper = styled.div`
  color: ${({ theme }) => theme.colors.darkBlack};
  background: ${({ theme }) => theme.colors.grey2};
  padding: 1em 0 3em 0;
  h2 {
    font-family: "Bebas Neue Bold";
    font-size: 39px;
    padding-top: 0.5em;
    text-align: center;
  }

  ${media.large`
  h2 {font-size: 64px;}
  `}
`;

const Content = styled.div`
  padding-top: 20px;
  img {
    display: block;
    margin: 0 auto;
    height: 150px;
  }

  ${media.tablet`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 20px;

  img {
      margin: 0 15px 0 0;
  }
  `}

  ${media.large`
  img {
      height: 209px;
  }
  `}
`;

const Text = styled.div`
  text-align: center;

  h5 {
    padding-top: 10px;
    font-family: "Roboto-Bold";
    font-size: 22px;
    position: relative;
    display: inline-block;
    ::after {
      content: "";
      position: absolute;
      bottom: -10px;
      left: 0;
      border-bottom: #b5b5b4 solid 2px;
      width: 105%;
    }
  }
  p {
    margin: 5px 0;
    font-size: 18px;
    font-family: "Roboto-Regular";
  }

  ${media.tablet`
  text-align: left;

  p {
      margin: 0;
      font-size: 16px;
  }

  p:nth-child(2) {
      padding-top: 10px;
  }

  
  `}

  ${media.large`
  padding-left: 30px;
    h5 {
        font-size: 32px;
    }

    p {
      font-size: 21px;
  }
  `}
`;
const GreenSpan = styled.span`
  color: ${({ theme }) => theme.colors.darkGreen};
`;
const ContactInfo = () => {
  return (
    <Wrapper>
      <h2>
        kontakt dla <GreenSpan> Mediów </GreenSpan>
      </h2>
      <Content>
        <img src={profil} alt="" />
        <Text>
          <h5>Urszula Pietrzak</h5>
          <p>536 770 900</p>
          <p>ula@profeina.pl</p>
        </Text>
      </Content>
    </Wrapper>
  );
};

export default ContactInfo;
